.host {
  padding: 50px;

  h2 {
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 2.8rem;
  }

  p {
    margin: 20px 0;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.2rem;
  }
}

.field {
  width: auto;

  label {
    font-size: 1.4rem;
    line-height: 1.6rem;
  }
}

.actionBtn {
  margin-top: 20px;
  height: 48px;
  font-size: 1.6rem;
  font-weight: 600;
}

.closeBtn {
  margin-top: 10px;
}

.fileUploadMessage {
  margin: 20px 0 10px !important;
}

.fileValue {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start !important;
  text-align: left !important;

  img {
    margin: 0;
    margin-right: 20px;
  }

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;

    p {
      margin: 0;
      margin-bottom: 7px;
      color: #818181;
      font-weight: 400;
    }

    h3 {
      margin: 0;
      color: #000;
      font-weight: 500;
    }

    p,
    h3 {
      font-size: 1.2rem;
      line-height: 1.6rem;
    }
  }
}

.fieldSet {
  display: grid;
  align-items: center;
  grid-auto-flow: column;
  grid-gap: 20px;
  width: 100%;

  .field input {
    width: 100%;
  }
}

.previewImage {
  margin-bottom: 20px;
}
