.host {
  border-radius: 10px;
  background-color: #fff;
  padding: 30px;

  h2 {
    font-weight: 600;
    font-size: 2rem;
    line-height: 2.8rem;
    margin-bottom: 20px;
  }

  button {
    margin-top: 30px;
    height: 48px;
    width: 400px;
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 1.8rem;
  }
}
.field {
  label {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.6rem;
    margin-bottom: 10px;
  }
}

.reportFields {
  display: grid;
  grid-template-columns: 250px 1fr 250px;
  grid-gap: 20px;
}

.dateRangeField {
  width: 250px;

  input {
    font-size: 1.6rem;
  }
}

.classField {
  width: 250px;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
