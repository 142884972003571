.host {
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 30px;

  h2 {
    color: #343a40;
    font-weight: 500;
    line-height: 2.7rem;
    font-size: 2rem;
  }

  h3 {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.6rem;
    color: #212529;
    margin-right: 10px;
  }
}

.connections {
  p {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.6rem;
    margin-top: 20px;
  }
}

.iikoApiList {
  margin-top: 20px;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-gap: 30px;
  margin-bottom: 30px;
  justify-content: start;
}

.connectionStatus {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.connectionBadge {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.3rem;
}

.actions {
  display: flex;
  align-items: center;
}

.actionBtn {
  button {
    font-size: 1.2rem !important;
    line-height: 1.6rem;
    font-weight: 500 !important;
    padding: 8.5px 12px;
  }

  &:not(:last-child) {
    margin-right: 10px;
  }
}

.serverSettingsIcon {
  width: 14px;
  height: 14px;
  background-size: 14px;
}

.iikoServerModal {
  padding: 50px;

  h2 {
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 2.8rem;
    color: #333;
  }

  .field {
    margin-top: 20px;

    &:last-child {
      margin-bottom: 20px;
    }
  }

  .actionBtn {
    height: 48px;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 1.8rem;
  }
}

.syncLoadingOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.85);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  p {
    margin-top: 20px;
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 2.8rem;
    text-align: center;
  }

  :global(.ball-clip-rotate) > div {
    width: 36px;
    height: 36px;
  }
}

.syncNotificationIcon {
  path {
    fill: #fff;
  }
}

.iikoServerUrlField {
  position: relative;
}

.iikoServerUrlFieldTooltip {
  position: absolute;
  top: 0;
  left: 228px;
  z-index: 1;
}

.iikoServerUrlFieldTooltipInner {
  text-align: left;
}
