.host {
  box-shadow: none;
  position: relative;
}

.header {
  background: inherit;
  border-bottom: none;
  padding: 0px;
}

.close {
  opacity: 1;
  text-shadow: none;
  padding: 0px;
  color: #fff !important;
  font-size: 52px;
  font-weight: normal;
  position: absolute;
  right: -25px;
  top: 8px;
  padding: 0 !important;
  transition: var(--base-transition);
}

.content {
  border-radius: 6px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.12);
}

.body {
  padding: 0px;
}

:global(.modal-open) {
  padding-right: 0 !important;
  overflow: visible !important;
}
