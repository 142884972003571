.modal {
  min-width: 500px;
}

.host {
  padding: 50px;

  h2 {
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 2.8rem;
    margin-bottom: 20px;
  }

  p {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.2rem;
    margin-bottom: 20px;
  }
}

.field {
  margin-bottom: 20px;
}

.responsible {
  color: #c4c4c4;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.6rem;
  margin-left: 8px;
}

.responsibleLabel {
  margin-left: 22px;
  font-size: 1.4rem;
  line-height: 1.6rem;
}

.responsibleLabelWrapper {
  margin-bottom: 0 !important;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer !important;
}

:global(.select-item.selected):hover {
  background: var(--rmsc-hover) !important;
}
