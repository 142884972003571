@import 'animate.css/animate.min.css';

/* Fade In/Out */

.fadeIn {
  animation: fadeIn 0.3s ease-in;
}

.fadeOut {
  animation: fadeOut 0.3s ease-in;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.animate__faster {
  animation-duration: 200ms !important;
}
