:root {
  --primary-inactive-color: #FFEDBE;

  /* Typography */
  --primary-font: Manrope, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans,
    Helvetica Neue, sans-serif;
  --typography-primary-color: #333333;
  --typography-secondary-color: #595858;
  --typography-header-color: #000;

  /* Animation */
  --base-transition-timings: 0.2s ease-in-out;
  --base-transition: all var(--base-transition-timings);

  /* Sidebar */
  --sidebar-bg-color: #F9F9F9;
}
