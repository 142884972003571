.host {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1100;
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  background-color: rgba(255, 255, 255, 0.6);
  justify-content: center;
  align-items: center;
  pointer-events: none;
  opacity: 0;

  img {
    backface-visibility: hidden;
    will-change: transform;
    animation: zoom-in-zoom-out 6s linear infinite;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.message {
  text-align: center;
  font-weight: 600;
  font-size: 20px;
  margin-top: 20px;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
