.host {
  padding: 0;
  margin: 0;
  background: #f9fafb;
  margin-left: -30px;
  width: calc(100% + 60px);
  margin-top: -30px;
  margin-bottom: 30px;
}

.host :global(.page-title-heading) {
  font-size: 2rem;
  font-weight: 500;
}

.host :global(.page-title-icon) {
  justify-content: center;
  box-shadow: none;
  background-color: #fefefe;

  svg {
    height: 36px;
    width: 36px;
  }
}
