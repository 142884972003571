.host {
  :global(.table-responsive) {
    position: relative;
    overflow: auto;
    overscroll-behavior: contain;
  }
}

.table {
  thead {
    position: sticky;
    top: 0;
  }
}

.noDataMessage {
  font-size: 1.4rem;
}
