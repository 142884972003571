.header,
.card {
  background-color: transparent;
}

.card {
  box-shadow: none;
}

.header {
  border: none;
  box-shadow: none;
}

.headerItems {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 2px;
  overflow-y: hidden;
  width: fit-content !important;
}

.headerItem {
  font-size: 1.2rem;
  font-weight: 500;
  width: fit-content;

  a {
    color: #000 !important;
    border-radius: 6px 6px 0px 0px;
    border: 1px solid #e8e8e8 !important;
    padding: 10px 15px !important;
    line-height: 1.6rem;
    white-space: pre;

    &::before {
      display: none;
    }

    &:global(.active) {
      border-bottom-color: transparent !important;
    }
  }
}

.content {
  background-color: #fff;
  border: 1px solid #e8e8e8 !important;
  border-top: none !important;
  border-radius: 0px 0px 6px 6px;
  padding: 30px;
}
