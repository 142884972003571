.actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.actionsLeftSection {
  display: grid;
  align-items: center;
  grid-gap: 20px;
  grid-auto-flow: column;
}

.addSchoolBtn {
  height: 33px;

  button {
    font-size: 1.2rem !important;
    font-weight: 500 !important;

    &:last-child {
      white-space: pre;
    }
  }
}

.schoolsFilter {
  width: 250px;
  height: 33px;

  :global(.dropdown-content) {
    z-index: 2 !important;
  }

  :global(.dropdown-heading),
  :global(.dropdown-container) {
    height: 33px !important;
  }

  :global(.dropdown-heading-value) {
    font-size: 1rem;
  }

  :global(.dropdown-heading-dropdown-arrow) {
    transform: scale(0.6);
    margin-right: -6px;
  }

  :global(.dropdown-search-clear-icon) {
    transform: scale(0.6);
    margin-right: 0;
  }

  input {
    font-size: 1rem;
  }

  :global(.item-renderer) {
    font-size: 1rem;
  }
}

.table {
  margin-top: 30px;
}

.schoolCell {
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  width: 300px;
  text-overflow: ellipsis;
  text-align: left;
}

.linkCell {
  a {
    text-decoration: underline;
  }
}

.deleteModal {
  padding: 30px;
  text-align: center;

  h2 {
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 2.8rem;
    margin-bottom: 20px;
  }

  p {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 2.2rem;
    margin-bottom: 16px;
  }

  .modalActions {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 10px;
  }

  button {
    height: 48px;
    font-size: 1.6rem;
    font-weight: 600;
  }
}

.setupTeacherCell {
  text-align: left;

  a {
    color: var(--primary-color);
    text-decoration: underline;
  }
}

.invalidRow {
  background-color: var(--table-invalid-row-bg-color);
}
