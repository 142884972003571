.modal {
  min-width: 500px;
}

.host {
  max-height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 50px;

  h2 {
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 2.8rem;
    margin-bottom: 20px;
  }
}
