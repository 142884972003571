.host {
  padding: 50px;

  h2 {
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 2.8rem;
  }

  p {
    margin: 20px 0;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.2rem;
  }
}

.actionBtn {
  margin-top: 6px;
  height: 48px;
  font-size: 1.6rem;
  font-weight: 600;
}

.form {
  margin-top: 20px;
}
