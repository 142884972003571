.actions {
  margin-bottom: 30px;
}

.table {
  thead {
    th {
      padding: 16px 8px;
    }
  }
}

.addStudentsBtn {
  height: 33px;
  border-radius: 6px;

  button {
    font-size: 1.2rem !important;
  }
}

.negativeBalance {
  color: #d4261e;
}

.studentPhoto {
  width: 38px;
  height: 38px;
  object-fit: cover;
  border-radius: 4px;
}

.addStudentsModal {
  padding: 50px;

  h2 {
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 2.8rem;
    margin-bottom: 20px;
  }

  p {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 2.2rem;
    margin-bottom: 20px;
  }

  .modalActions {
    display: grid;
    grid-gap: 10px;
  }

  button {
    height: 48px;
    font-size: 1.6rem;
    font-weight: 600;
  }
}

.actionsRow {
  display: grid;
  grid-gap: 20px;
  grid-auto-flow: column;
}

.matchMealTypeModalHost {
  max-width: 900px;
  max-height: 700px;
}

.matchMealTypeModal {
  padding: 50px;

  h2 {
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 2.8rem;
    margin-bottom: 20px;
  }

  p {
    font-size: 1.6rem;
    line-height: 1.9rem;
    margin-bottom: 20px;
  }

  button {
    margin-top: 20px;
    font-size: 1.6rem;
    line-height: 1.8rem;
    font-weight: 600;
    width: 200px;
    height: 48px;
  }

  .studentsMealTable {
    max-height: 500px;
    overflow: auto;
  }

  .matchMealTypeModalActions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .mealTypeCheckbox {
    cursor: pointer;
    user-select: none;
  }
}
