@import './fonts.scss';
@import './architectUI/index.scss';
@import './variables.scss';
@import './icons.scss';
@import './animations.scss';

* {
  box-sizing: border-box;
  line-height: 1;
  border: none;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

html {
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
}

html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
  font-size: 10px;
  color: var(--typography-primary-color);
  font-family: var(--primary-font);
}

body {
  font-variant-ligatures: no-common-ligatures;
  text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
}

#root {
  width: 100%;
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
  transition: var(--base-transition);
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}

button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
}

button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.btn-primary {
  &:disabled {
    background-color: var(--btn-primary-bg-color);
    border-color: var(--btn-primary-bg-color);
    color: var(--btn-primary-text-color);
  }

  background-color: var(--btn-primary-bg-color);
  border-color: var(--btn-primary-bg-color);
  color: var(--btn-primary-text-color);

  &:hover {
    background-color: var(--btn-primary-hover-bg-color);
    border-color: var(--btn-primary-hover-bg-color);
  }

  svg {
    path {
      fill: var(--btn-primary-text-color) !important;
    }
  }
}

.btn-primary-light {
  color: var(--btn-primary-text-color);

  &:hover {
    color: var(--btn-primary-text-color);
  }
}

.btn-link {
  color: var(--btn-link-text-color);
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: var(--primary-color);
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  background-color: var(--btn-primary-hover-bg-color);
  border-color: var(--btn-primary-hover-bg-color);
}

.ball-clip-rotate > div {
  border-top-color: var(--primary-color);
  border-right-color: var(--primary-color);
  border-left-color: var(--primary-color);
}
