.host {
  padding: 30px;
  text-align: center;

  h2 {
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 2.8rem;
    margin-bottom: 30px;
  }
}

.actions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.actionBtn {
  height: 48px;
  font-size: 1.6rem;
  font-weight: 600;
}

.modal :global(.modal-content) {
  border-radius: 12px;
}
