.Toastify__toast-container {
  top: 50px;
  right: 30px;
  width: 355px;
}

.Toastify__toast {
  border-radius: 12px;
  font-weight: 500;
  font-size: 1.6rem;
  padding: 14px 20px;
  align-items: center;
  justify-content: space-between;
  font-family: inherit;

  &--success {
    background: var(--success-color);
  }

  &--error {
    background: var(--warning-color);
  }

  div {
    line-height: 2rem !important;
    white-space: break-spaces;
    word-break: break-word;
  }
}

.Toastify__toast-body {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  width: 100%;
}

.Toastify__toast-icon {
  margin-left: 10px;
}
