.host {
  width: 190px;

  :global(.rs-picker-toggle) {
    height: 34px;
    box-shadow: none;
    display: flex;
    align-items: center;

    &:hover:not(:global(.rs-picker-toggle-active)) {
      border: 1px solid #e5e5e5 !important;
    }
  }

  input {
    ::placeholder {
      color: #c4c4c4 !important;
      font-weight: 500 !important;
    }
  }
}

.arrow {
  transition: var(--base-transition);
  position: absolute;
  right: 13px;
  z-index: 9;
  top: 14px;
}

.wrapper {
  position: relative;
  height: 34px;
}

:global(.rs-picker-toggle-active) {
  border-color: var(--primary-color) !important;
}

:global(.rs-picker-toggle) {
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #e5e5e5;
  font-size: 1rem !important;
  line-height: 1.3rem !important;
  font-weight: 500;
  color: #c4c4c4;
}

:global(.rs-picker-toggle-value) {
  color: #000 !important;
}

:global(.rs-picker-toggle-textbox) {
  color: #000 !important;
}

:global(.rs-calendar-table-cell-content) {
  &:hover {
    background-color: var(--calendar-range-color) !important;
    color: var(--primary-color) !important;
  }
}
:global(.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content) {
  box-shadow: inset 0 0 0 1px var(--primary-color);
}
:global(.rs-calendar-table-cell-selected .rs-calendar-table-cell-content) {
  background-color: var(--calendar-selected-cell-bg-color);
  color: var(--calendar-selected-cell-text-color);

  span {
    color: var(--calendar-selected-cell-text-color);
  }
}

:global(.rs-calendar-table-cell-in-range:before) {
  background-color: var(--calendar-range-color);
}

:global(.rs-anim-fade.rs-anim-in) {
  z-index: 8;
}
:global(.rs-btn.rs-btn-primary.rs-btn-sm) {
  background-color: var(--primary-color);
  color: var(--calendar-selected-cell-text-color);
  font-weight: 500;
  font-size: 1.2rem;
}

:global(.rs-calendar-table-cell-day) {
  color: #000;
  font-weight: 500;
}

:global(.rs-calendar-header-title) {
  color: #000;
  font-weight: 500;
}

:global(.rs-calendar-table-cell-un-same-month .rs-calendar-table-cell-day) {
  color: #c4c4c4;
}

:global(.rs-btn.rs-btn-link.rs-btn-sm) {
  color: var(--primary-color);
  font-weight: 500;
  font-size: 1.2rem;
}

:global(.rs-picker-daterange-menu .rs-calendar:first-child) {
  border-right: 1px solid #c4c4c4;
}

:global(.rs-picker-daterange-header) {
  border-bottom: 1px solid #c4c4c4;
}

:global(.rs-picker-toolbar) {
  border-top: 1px solid #c4c4c4;
}

:global(.rs-picker-menu) {
  border-radius: 7px;
  box-shadow: 0px 0px 4px rgb(0 0 0 / 12%), 0 0 2px rgb(0 0 0 / 6%);
}

:global(.rs-picker-toggle-caret) {
  display: none;
}

:global(.rs-btn-close) {
  padding: 0 !important;
  top: 7px !important;
}

:global(.rs-picker-toggle .rs-picker-toggle-placeholder) {
  color: #c4c4c4 !important;
  font-weight: 500;
}
