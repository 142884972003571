.schoolsFilter {
  width: 250px;
  height: 33px;

  :global(.dropdown-content) {
    z-index: 2 !important;
  }

  :global(.dropdown-heading),
  :global(.dropdown-container) {
    height: 33px !important;
  }

  :global(.dropdown-heading-value) {
    font-size: 1rem;
  }

  :global(.dropdown-heading-dropdown-arrow) {
    transform: scale(0.6);
    margin-right: -6px;
  }

  :global(.dropdown-search-clear-icon) {
    transform: scale(0.6);
    margin-right: 0;
  }

  input {
    font-size: 1rem;
  }

  :global(.item-renderer) {
    font-size: 1rem;
  }
}

.topActions {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}

.filters {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 20px;
}

.addRequestBtn {
  button {
    font-weight: 500 !important;
    font-size: 1.2rem !important;
  }
}

.counterHeaderCell {
  span {
    font-size: 1rem;
    font-weight: 400;
  }
}

.modal {
  padding: 50px;

  h2 {
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 2.8rem;
  }

  p {
    margin: 20px 0;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.2rem;
  }
}

.field {
  label {
    font-size: 1.4rem;
    line-height: 1.6rem;
  }
}

.submitBtn {
  margin-top: 20px;
  height: 48px;
  font-size: 1.6rem;
  font-weight: 600;
}
