.host {
  height: 33px;

  :global(.dropdown-menu) {
    border-radius: 4px;
    border: none;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
    min-width: 168px;
    transform: translate3d(0px, 34px, 0px) !important;
    padding: 0;
    animation: none;

    &::before,
    &::after {
      display: none;
    }
  }

  button {
    background-color: transparent !important;
    border: none;
    padding: 0;
    margin: 0;
  }
}

.trigger {
  width: 170px;
  height: 33px;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  color: #000;
  font-size: 1rem;
  line-height: 1.3rem;
  text-align: left;
  display: flex;
  align-items: center;
  padding: 10px;
  transition: var(--base-transition);
  background-color: #fff;
}

.focus {
  border-color: var(--primary-color);
}

.option {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  padding: 8px 10px;
  margin-bottom: 0px;

  * {
    cursor: pointer;
  }

  label {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 1rem;
    line-height: 1.6rem;

    input {
      width: 14px;
      height: 14px;
      margin: 0 !important;
      background-size: auto;
      background-color: transparent !important;
      border-color: transparent !important;
      background-position: center;

      &:focus {
        box-shadow: none;
      }
    }

    span {
      margin-left: 24px;
    }
  }
}

.placeholder {
  color: #c4c4c4;
}

.arrow {
  transition: var(--base-transition);
  position: absolute;
  right: 13px;
}
