.host {
  display: flex;
  align-items: center;

  input {
    font-size: 1rem;
    height: 33px;
    border-radius: 4px;
    border: 1px solid #E5E5E5 !important;
    width: 280px;

    &::placeholder {
      color: #C4C4C4;
    }
  }
}

.refreshBtn {
  margin-left: 10px;
}
