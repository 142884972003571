.host {
  :global(.dropdown-toggle) {
    cursor: pointer;

    &::after {
      display: none;
    }
  }

  :global(.dropdown-menu) {
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
    border-radius: 6px;
    min-width: 200px;
    width: fit-content;
    padding: 0;
    margin: 0;
    overflow: hidden;
    border: none;
    background-color: #F9FAFC;

    :global(.dropdown-item) {
      min-height: 36px;
      font-size: 1.4rem;
      padding: 6px 12px;

      &:not(:last-child) {
        border-bottom: 1px solid #eff1f3;
      }

      &:active {
        :global(.dropdown-icon) {
          color: #fff;
        }
      }
    }

    :global(.dropdown-icon) {
      width: 20px;
      height: 100%;
      margin-right: 12px;
      margin-left: 0;
      background-size: cover;
      opacity: 1;
      color: var(--primary-color);
      font-size: 1.8rem;
    }
  }
}
