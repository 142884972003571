.host {
  padding: 50px;
  max-height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;

  h2 {
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 2.8rem;
  }

  p {
    margin: 20px 0;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.2rem;
  }
}

.field {
  label {
    font-size: 1.4rem;
    line-height: 1.6rem;
  }
}

.submitBtn {
  margin-top: 20px;
  height: 48px;
  font-size: 1.6rem;
  font-weight: 600;
}
