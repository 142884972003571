.host {
  font-size: 1.2rem;
  font-weight: 500;
  border-radius: 6px;
  transition: var(--base-transition);

  button {
    display: flex;
    align-items: center;
    justify-content: center;

    &:first-child {
      border-top-left-radius: 6px !important;
      border-bottom-left-radius: 6px !important;

      svg {
        width: 14px;
        height: 14px;

        path {
          fill: #000;
        }
      }
    }

    &:last-child {
      border-top-right-radius: 6px !important;
      border-bottom-right-radius: 6px !important;
      z-index: 1 !important;
      padding: 6px 12px;
    }
  }

  &:global(.btn-link) {
    text-decoration: underline;

    &:hover {
      color: var(--primary-color);
    }
  }

  &:disabled {
    opacity: 0.3;
  }
}
