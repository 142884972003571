.iconImg {
  width: 34px;
  height: 34px;
  position: absolute;
  left: 5px;
  top: 50%;
  margin-top: -18px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    border-radius: 50%;
    width: 24px;
    height: 24px;
  }
}

.itemActive {
  .titleAndIcon {
    font-weight: 600;
  }
}

.hasActiveChild,
.itemActive {
  transition: var(--base-transition);
  background-color: var(--sidebar-active-item-bg-color);
  border-radius: 6px;
}

.itemWrapper {
  padding: 0 10px;

  li {
    padding: 0 !important;
  }
}

.itemTitle {
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  font-size: 1.4rem;
  color: var(--typography-primary-color) !important;
  background: transparent !important;
  white-space: pre;

  &:hover {
    color: var(--typography-primary-color);
  }

  i:global(.light-menu-icon) {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    margin-right: 10px;
  }

  svg {
    margin-right: 15px;
    min-width: 24px;
    min-height: 24px;
  }

  &.withoutSubItems {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
}

.titleAndIcon {
  width: 100%;
  display: flex;
  align-items: center;
  font-weight: 400;
  height: 44px;
}

:global(.has-active-child) {
  .titleAndIcon {
    font-weight: 600;
  }
}

.chevron {
  display: flex;
  align-items: center;
  justify-content: center;
}

.invisible {
  display: none;
}

.disabled {
  pointer-events: none;
  opacity: 0.3;
}
