.host {
  padding: 50px;

  h2 {
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 2.8rem;
    margin-bottom: 20px;
  }

  p {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 2.2rem;
    margin-bottom: 16px;
  }
}

.field {
  margin-bottom: 20px;

  input {
    width: inherit;
  }

  label {
    white-space: pre;
  }
}

.fieldSet {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.actions {
  display: grid;
  grid-gap: 10px;
}

.actionBtn {
  height: 48px;
  font-size: 1.6rem;
  font-weight: 600;
}
