.host {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: flex-start;
  min-height: 550px;
  width: 335px;

  form {
    width: 100%;
    height: 230px;
    position: relative;
  }

  h1 {
    color: #000;
    font-weight: 700;
    text-align: center;
    line-height: 2.2rem;

    span {
      color: var(--primary-color);
    }
  }

  h2 {
    margin-top: 30px;
    font-weight: 600;
    font-size: 2.4rem;
  }
}

.footerMessage {
  margin-top: 10px;
  text-align: center;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.8rem;
  white-space: pre;

  a,
  span {
    font-weight: 600;
    color: var(--primary-color);
  }
}

.authMessage {
  font-size: 1.4rem;
  line-height: 1.8rem;
  font-weight: 600;
  text-align: center;
  color: var(--typography-secondary-color);
  margin-bottom: 20px;
  margin-top: 10px;
}

.field {
  font-weight: 600;
  font-size: 2rem;

  input:disabled {
    opacity: 1;
    background-color: #fff;
  }

  :global(.phoneFieldRegionSign) {
    padding: 12px 18px !important;
    min-height: 48px;
  }

  input {
    font-weight: 600;
    padding: 11px 20px;
    min-height: 48px;
  }
}

.submitBtn {
  padding: 11px;
  font-weight: 600;
  font-size: 1.6rem;
  position: absolute;
  bottom: 0;
  left: 0;
}

.codeField {
  margin-top: 20px;
}

.changeNumberBtn {
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.8rem;
  margin-top: 10px;
}

.sendCodeAgainBtn {
  color: var(--typography-secondary-color);
  font-size: 1.4rem;
  line-height: 1.8rem;
  font-weight: 600;
}
