.topActions {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}

.filters {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 20px;
}

.modal {
  padding: 50px;

  h2 {
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 2.8rem;
    margin-bottom: 20px;
  }
}

.field {
  margin-top: 10px;

  label {
    font-size: 1.4rem;
    line-height: 1.6rem;
  }
}

.submitBtn {
  margin-top: 20px;
  height: 48px;
  font-size: 1.6rem;
  font-weight: 600;
}

.fieldSet {
  display: grid;
  align-items: center;
  grid-auto-flow: column;
  grid-gap: 20px;
  width: 100%;

  .field input {
    width: 100%;
  }
}

.checkboxField {
  margin-top: 44px;
}

.nutritionClassCell {
  line-height: 1.6rem;
}