.host {
  margin-top: 30px;
}

.table {
  margin-top: 30px;
}

.tokenCell {
  text-decoration: underline;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  svg {
    min-width: 14px;
    min-height: 14px;
    margin-left: 5px;
  }

  span {
    width: 206px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
    transition: var(--base-transition);

    &:hover {
      color: var(--primary-color);
    }
  }
}

.actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tokenHeaderCell {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  svg {
    margin-left: 8px;
    cursor: pointer;
  }
}

.multiRowCell {
  display: grid;
  grid-gap: 2px;
  width: fit-content;
  justify-content: start;

  & > span,
  & > div {
    height: 30px;
    display: flex;
    align-items: center;
  }
}

.schoolsFilter {
  width: 250px;
}

.connectionFilter {
  width: 170px;
}

.multiSelectFilter {
  height: 33px;

  :global(.dropdown-content) {
    z-index: 2 !important;
  }

  :global(.dropdown-heading),
  :global(.dropdown-container) {
    height: 33px !important;
  }

  :global(.dropdown-heading-value) {
    font-size: 1rem;
  }

  :global(.dropdown-heading-dropdown-arrow) {
    transform: scale(0.6);
    margin-right: -6px;
  }

  :global(.dropdown-search-clear-icon) {
    transform: scale(0.6);
    margin-right: 0;
  }

  input {
    font-size: 1rem;
  }

  :global(.item-renderer) {
    font-size: 1rem;
  }
}

.filters {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 20px;
}
