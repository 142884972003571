.linkCell {
  a {
    text-decoration: underline;
    transition: var(--base-transition);

    &:hover {
      color: var(--primary-color);
    }
  }
}
