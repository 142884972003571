.logo {
  padding: 0 20px;

  h1 {
    font-size: 1.8rem;
    line-height: 2.2rem;
    color: var(--typography-header-color);
    font-weight: 700;
    white-space: pre;

    span {
      color: var(--primary-color);
    }
  }

  img {
    height: 24px;
  }
}

.toggleTrigger {
  cursor: pointer;
}

.logoutBtn,
.addSchoolBtn button {
  font-size: 1.2rem !important;
  line-height: 1.6rem !important;
  padding: 9.5px 12px !important;
  font-weight: 500;
}

.logoutBtn {
  width: auto;
  margin-left: 20px;
}

.logoShort {
  width: 30px;
  height: auto;
}