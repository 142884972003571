.host {
  display: flex;
  flex-direction: column;
  font-weight: 400;
  font-family: inherit;
  position: relative;
  width: 100%;
  font-size: 1.6rem;

  input,
  textarea {
    border-radius: 6px;
  }

  input,
  .codeField input {
    height: 48px;
  }

  input,
  textarea,
  .codeField input {
    border: 1px solid #e8e8e8;
    color: #000 !important;
    padding: 16px;
    transition: var(--base-transition);

    &[data-valid='true'],
    &:focus {
      border-color: var(--primary-color);
    }
  }

  .phoneField input:focus {
    border-color: #e8e8e8;
  }

  .codeField input {
    text-align: center;
    border-radius: 12px;
    width: 45px;
  }

  textarea::placeholder,
  & > input::placeholder {
    color: #818181;
  }

  .phoneField {
    display: flex;
    align-items: center;
    width: 100%;

    :global(.phoneFieldRegionSign) {
      transition: var(--base-transition);
      height: 100%;
      border: 1px solid #e8e8e8;
      border-radius: 6px;
      padding: 15px;
      color: #000;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: none;
      background-color: #fff;
      height: 48px;
    }

    input {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      width: 100%;
    }
  }

  label {
    margin-bottom: 10px;
  }
}

.invalid input,
.invalid .phoneField > div:first-child,
.invalid textarea,
.invalid .dropZoneContent,
.invalid :global(.dropdown-container) {
  border-color: var(--warning-color) !important;
}

:global(.rmsc .dropdown-container[aria-disabled='true']:focus-within) {
  box-shadow: none;
  border: 1px solid #e8e8e8;
}

:global(.rmsc .dropdown-container[aria-disabled='true']) {
  cursor: not-allowed;
  background-color: #fafafa !important;
}

.error,
.requiredStar {
  color: var(--warning-color) !important;
}

.invalid input:disabled,
.invalid textarea:disabled {
  opacity: 0.7;
}

.codeField {
  display: grid !important;
  grid-auto-flow: column;
  justify-content: center;
  grid-gap: 10px;
}

.dropZoneContent {
  text-align: center;
  color: #818181;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  line-height: 1.4rem;
  font-weight: 400;
  height: 80px;
  transition: var(--base-transition);
  cursor: pointer;
  border: #e8e8e8 dashed 2px;

  img {
    margin-bottom: 7px;
  }
}

.dragAccepted {
  border-color: var(--success-color);
}

.dragRejected {
  border-color: var(--warning-color);
}

:global(.rmsc) {
  --rmsc-main: var(--primary-color) !important;
  --rmsc-h: 38px !important;
  --rmsc-selected: transparent;

  input {
    height: 16px;
    width: 16px;
  }

  :global(.options) {
    max-height: 152px;
    position: relative;
  }

  :global(.dropdown-container) {
    border: 0.5px solid #e8e8e8;
  }

  :global(.dropdown-heading) {
    height: 48px;
  }

  :global(.dropdown-content) {
    padding-top: 2px !important;
  }

  :global(.item-renderer) {
    align-items: center;

    & > span {
      margin-left: 26px;
      word-break: break-word;
    }
  }

  :global(.select-item) {
    margin-bottom: 0 !important;
  }

  :global(.form-check-input[type='checkbox']) {
    width: 18px;
    height: 18px;
    margin: 0 !important;
    background-color: transparent !important;
    border-color: transparent !important;
    padding: 0;
  }
}

.checkbox {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;

  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  &::before {
    content: '';
    display: inline-block;
    width: 18px;
    height: 18px;
    flex-shrink: 0;
    flex-grow: 0;
    border: 1px solid #e8e8e8;
    border-radius: 0.25em;
    margin-right: 0.5em;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
  }

  &:not(:disabled):not(:checked):hover::before {
    transition: var(--base-transition);
    border-color: var(--primary-color);
  }

  &:not(:disabled):active::before {
    transition: var(--base-transition);
    border-color: var(--primary-color);
  }

  .checkMark {
    border-radius: 4px;
    position: absolute;
    width: 18px;
    height: 18px;
    border: 1px solid var(--primary-color);
    background-image: url('../../../assets/img/icons/checkmark.svg');
    background-repeat: no-repeat;
    background-position: 50%;
  }
}

.dateRange {
  height: 48px;

  :global(.rs-picker-toggle) {
    height: 48px;
    box-shadow: none;
    display: flex;
    align-items: center;

    &:hover:not(:global(.rs-picker-toggle-active)) {
      border: 1px solid #e5e5e5 !important;
    }
  }

  input {
    border: none !important;

    ::placeholder {
      color: #c4c4c4 !important;
      font-weight: 500 !important;
    }
  }
}

:global(.rs-picker-toggle-active) {
  border-color: var(--primary-color) !important;
}

:global(.rs-picker-toggle) {
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #e5e5e5;
  font-size: 1rem !important;
  line-height: 1.3rem !important;
  font-weight: 500;
  color: #c4c4c4;
}

:global(.rs-picker-toggle-value) {
  color: #000 !important;
}

:global(.rs-picker-toggle-textbox) {
  color: #000 !important;
}

:global(.rs-picker-toggle-read-only) {
  opacity: 1;
}

:global(.rs-calendar-table-cell-content) {
  &:hover {
    background-color: var(--calendar-range-color);
    color: var(--primary-color) !important;
  }
}
:global(.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content) {
  box-shadow: inset 0 0 0 1px var(--primary-color);
}
:global(.rs-calendar-table-cell-selected .rs-calendar-table-cell-content) {
  background-color: var(--calendar-selected-cell-bg-color);
  color: var(--calendar-selected-cell-text-color);

  span {
    color: var(--calendar-selected-cell-text-color);
  }
}

:global(.rs-calendar-table-cell-in-range:before) {
  background-color: var(--calendar-range-color);
}

:global(.rs-anim-fade.rs-anim-in) {
  z-index: 8;
}
:global(.rs-btn.rs-btn-primary.rs-btn-sm) {
  background-color: var(--primary-color);
  color: var(--calendar-selected-cell-text-color);
  font-weight: 500;
  font-size: 1.2rem;
}

:global(.rs-calendar-table-cell-day) {
  color: #000;
  font-weight: 500;
}

:global(.rs-calendar-header-title) {
  color: #000;
  font-weight: 500;
}

:global(.rs-calendar-table-cell-un-same-month .rs-calendar-table-cell-day) {
  color: #c4c4c4;
}

:global(.rs-btn.rs-btn-link.rs-btn-sm) {
  color: var(--primary-color);
  font-weight: 500;
  font-size: 1.2rem;
}

:global(.rs-picker-daterange-menu .rs-calendar:first-child) {
  border-right: 1px solid #c4c4c4;
}

:global(.rs-picker-daterange-header) {
  border-bottom: 1px solid #c4c4c4;
}

:global(.rs-picker-toolbar) {
  border-top: 1px solid #c4c4c4;
}

:global(.rs-picker-menu) {
  border-radius: 7px;
  box-shadow: 0px 0px 4px rgb(0 0 0 / 12%), 0 0 2px rgb(0 0 0 / 6%);
}

:global(.rs-picker-toggle-caret) {
  display: none;
}

:global(.dropdown-heading-dropdown-arrow) {
  color: #000 !important;
}

:global(.rmsc .dropdown-heading .dropdown-heading-value) {
  span {
    font-weight: 500;
  }
}

span:global(.rs-btn-close) {
  padding: 0 !important;
  top: 50% !important;
  transform: translateY(-50%);
  right: 26px !important;
}

.passwordInputWrapper {
  position: relative;

  input {
    width: 100%;
    padding-right: 45px;
  }

  svg {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    user-select: none;
    cursor: pointer;
    width: 40px;
    height: 40px;
    padding: 8px;
  }
}
