.outer {
  background-color: var(--sidebar-bg-color);
}

.inner {
  padding: 20px 10px !important;
}

.schoolName {
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.schoolName:hover {
  overflow: visible;
  white-space: normal;
}
