.host {
  font-size: 1.2rem;
  font-weight: 500;
  border-radius: 6px;
  width: 100%;
  transition: var(--base-transition);

  &:global(.btn-link) {
    text-decoration: underline;

    &:hover {
      color: var(--primary-color);
    }
  }

  &:disabled {
    opacity: 0.3;
  }
}
