.titleIcon {
  width: 36px;
  height: 36px;
  background-size: 36px;
}

.tooltip {
  :global(.tooltip-inner) {
    text-align: left;
    max-width: 266px;
  }
}

.statusRow {
  display: flex;
  align-items: center;
}

.statusBadgeWrapper {
  display: flex;
  align-items: center;
}

.statusBadge {
  margin: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  :global(.spinner-border) {
    margin-right: 10px;
    width: 14px;
    height: 14px;
  }
}

.syncSection {
  p {
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.6rem;
    margin-bottom: 10px;
  }
}

.statusRow {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  p {
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.6rem;
    margin-bottom: 0;
  }
}

.syncBtn {
  margin-bottom: 20px;

  button {
    font-weight: 500;
    font-size: 1.2rem !important;
  }
}

.cycleSection {
  h2 {
    font-weight: 500;
    font-size: 2rem;
    margin-bottom: 20px;
  }

  .field {
    width: 400px;
  }
}

.tableOuter {
  width: fit-content;
  max-width: 100%;
  box-shadow: 0px 0px 12px 3px rgb(0 0 0 / 10%);
  border-radius: 6px;
  margin-top: 30px;
  overflow: auto;
}

.table {
  td,
  th {
    height: 48px;
    padding: 16px 10px;
    text-align: left;
    font-size: 1.4rem;
    background-color: #fff;
    border-top: 1px solid #eaedef;
    &:not(:last-child) {
      border-right: 1px solid #eaedef;
    }
  }

  th {
    border-top: none;
  }

  .headingCell {
    background: #f9fafc;
    font-weight: 600;
  }

  .headingRowCell {
    width: 130px;
    min-width: 130px;
  }

  .valueCell {
    width: 150px;
    min-width: 150px;
  }

  .filedCell {
    padding: 0;
  }
}

.actions {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 20px;
  justify-content: end;
  align-items: center;
  margin-top: 30px;

  button {
    width: 300px;
    height: 48px;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 1.8rem;
  }
}

.modal {
  padding: 30px;

  h2 {
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 2.8rem;
    margin-bottom: 10px;
    text-align: center;
  }

  p {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.2rem;
    text-align: center;
    margin-bottom: 20px;
  }

  .modalActions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;

    button {
      height: 48px;
      font-size: 1.6rem;
      font-weight: 600;
    }
  }
}

.dateInput {
  width: 400px;
  height: 50px;
}

.dateInputInvalid {
  border: 1px solid var(--warning-color);
}

.dateInputField {
  label {
    font-size: 1.4rem;
    font-weight: 400;
    margin-bottom: 10px;
  }
}
