.modal {
  max-width: 1102px;
}

.host {
  padding: 50px;

  h2 {
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 2.8rem;
    margin-bottom: 20px;
  }

  p {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.2rem;
  }
}

.actionBtn {
  margin-top: 20px;
  height: 48px;
  font-size: 1.6rem;
  font-weight: 600;
  width: 200px;

  &:last-child {
    margin-left: 20px;
  }
}

.closeBtn {
  margin-top: 10px;
}

.tableWrapper {
  min-height: 511px;
  margin-top: 20px;
  display: flex;
  justify-content: center;

  thead {
    position: relative;
    top: 0;
  }
}

.tableWrapperLoading {
  align-items: center;
}

.table {
  height: fit-content;
  width: 100%;

  th {
    height: 48px;
  }
}

.actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.invalidSection {
  font-size: 1.6rem;
  font-weight: 400;

  .error {
    color: var(--warning-color);
    line-height: 2rem;
    font-weight: 600;
  }

  ol {
    padding-left: 19px;
  }

  li {
    list-style: decimal;
    line-height: 2rem;

    &::marker {
      font-weight: 600;
    }
  }
}

.actionIcon {
  cursor: pointer;
  color: #545cd8;
  user-select: none;
}

.invalidCell {
  border: 2px solid var(--warning-color) !important;
  color: var(--warning-color);
}

.excludeCell {
  background-color: #f4f4f4;
  color: #b3b3b3;
}
