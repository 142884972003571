.actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.schoolsFilter {
  width: 250px;
  height: 33px;

  :global(.dropdown-content) {
    z-index: 2 !important;
  }

  :global(.dropdown-heading),
  :global(.dropdown-container) {
    height: 33px !important;
  }

  :global(.dropdown-heading-value) {
    font-size: 1rem;
  }

  :global(.dropdown-heading-dropdown-arrow) {
    transform: scale(0.6);
    margin-right: -6px;
  }

  :global(.dropdown-search-clear-icon) {
    transform: scale(0.6);
    margin-right: 0;
  }

  input {
    font-size: 1rem;
  }

  :global(.item-renderer) {
    font-size: 1rem;
  }
}
